const Kontakt = () => {
    return (
    <body>

        <div class="mail">
    
            <h1 id="mailHead">Kontakt via E-post:</h1>
            <p id="mailorm">För feedback:</p>
            <a href="mailto:feedback@skola77.com" id="feedbackMail">feedback@skola77.com</a>
            <p>Övrigt:</p>
            <a href="mailto:support@skola77.com">support@skola77.com</a>
    
    
        </div>
    
        
        
    </body>
    )
}
    export default Kontakt;