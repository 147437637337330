const OmOss = () => {
    return (
    <body>

       <div id="omTitle">

            <h1 id="omOssHead">Om oss</h1>


       </div>


        <div id="bodyOmOss">

        <h2><u>Kommer snart...</u></h2>
    

        </div>

    </body>
    )
}
    export default OmOss;