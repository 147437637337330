const Cookies = () => {
    return (
    <body>



<div id="cookies">

<h1>Cookie-uttalande</h1>



<p>På denna webbplats använder vi cookies. Detta cookie-uttalande beskriver användningen av cookies på skola77.com, samt vilka syften vi använder cookies för.</p>


<b>Definitioner:</b>


<ul>
<p>Cookies: en teknologi som gör det möjligt att lagra och läsa information i din webbläsare och som kan användas för många olika ändamål.</p>
<p>Förstaparts-cookies är cookies som sätts av och används av denna webbplats,</p>
<p>Tredjeparts-cookies är cookies som sätts och används av tredje parter, det vill säga andra webbplatser/tjänster vi använder på denna webbplats. Alla tredjeparts-cookies på denna webbplats är dokumenterade efter namn.</p>

</ul>

<p>Denna webbplats använder både förstaparts-cookies och tredjeparts-cookies.</p>

<p>Vi använder cookies för <u>följande</u> ändamål:</p>

<b>Funktionscookies</b>

<p>Dessa är nödvändiga cookies som ser till att denna webbplats fungerar som den ska.</p>

<b>Analys och statistik</b>

<p>Detta är cookies som används för trafikmätningar och analys av användningsmönster etc.</p>

<b>Personifiering/anpassning</b>

<p>Cookies som används för att anpassa webbplatsen baserat på vad vi vet om dig. Det kan till exempel vara att vi visar information om produkter/tjänster som vi tror kan vara intressanta för dig eftersom du har visat intresse för produkten/tjänsten vid tidigare besök på webbplatsen.</p>

<b>Marknadsföring</b>

<p>Detta är cookies som används för att styra vår marknadsföring, till exempel genom att visa annonser på andra webbplatser till dem som har besökt denna webbplats.</p>

<b>Sparning av klasser</b>

<p>Cookies som sparas för att kunna spara klasser. </p>

</div>


    </body>
    )
}
    export default Cookies;